import React, { useMemo } from 'react'
import { Link } from 'gatsby'

const Post = ({ node, query, prefix, hideDate, yearOnly }) => {
  //const date = new Date(node.date)
  //const oneMonthAgo = new Date()
  //oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
  //let isPrivate = false

  //if (date > oneMonthAgo) {
  //  isPrivate = true
  //}
  
  let isPrivate = !(node.published)

  let formattedDate
  let formattedYear
  if (node.date) {
    const dateArr = node.date.split(' ')

    dateArr[0] = dateArr[0].slice(0, 3)
    formattedDate = dateArr.join(' ')

    const year = dateArr.pop()
    formattedYear = year
  }

  const getTitle = (title, query) => {
    if (query) {
      const re = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
      const highlightStart = title.search(re)

      if (highlightStart !== -1) {
        const highlightEnd = highlightStart + query.length

        return (
          <h3>
            {title.slice(0, highlightStart)}
            <strong className="highlighted">
              {title.slice(highlightStart, highlightEnd)}
            </strong>
            {title.slice(highlightEnd)}
          </h3>
        )
      }
      return <h3>{title}</h3>
    }
    return <h3>{title}</h3>
  }

  //<span className="new-badge">{isPrivate && 'private writing'}</span>
  return (
    <Link
      to={prefix ? `/${prefix}${node.slug}` : node.slug}
      key={node.id}
      className={isPrivate ? 'post' : 'post'}>

      <span className="flex" style={{ alignItems: 'center' }}>
        {getTitle(node.title, query)}
        <h3p>{isPrivate && '(private writing)'}</h3p>
      </span>
      <div>
        {formattedDate && !hideDate && (
          <time>{yearOnly ? formattedYear : formattedDate}</time>
        )}
      </div>
    </Link>


  )
}


export const PostsList = ({
  data = [],
  showYears,
  query,
  prefix,
  hideDate,
  yearOnly,
  notfullList,
}) => {
  const postsByYear = useMemo(() => {
    const collection = {}

    data.forEach((post) => {
      const year = post.date?.split(', ')[1]

      collection[year] = [...(collection[year] || []), post]
    })

    return collection
  }, [data])
  const years = useMemo(() => Object.keys(postsByYear).reverse(), [postsByYear])
  const MAXPOST = 6

  if (showYears) {
    if (notfullList) {
      return years.map((year) => (
        <section className="segment">
          <h2 className="main-header">
            <span>{year}</span> <Link to={`/thought-years/${year}`}>View all</Link>
          </h2>
          <div className="posts">
            {postsByYear[year].slice(0,MAXPOST).map((node) => (
              <Post key={node.id} node={node} query={query} prefix={prefix} />
            ))}
          </div>
        </section>
      ))
    } else {
      return years.map((year) => (
        <section className="segment">
          <h2 className="main-header">
          <span>{year}</span>
            </h2>
          <div className="posts">
            {postsByYear[year].map((node) => (
              <Post key={node.id} node={node} query={query} prefix={prefix} />
            ))}
          </div>
        </section>
      ))
    }
  } else {
    return (
      <div className="posts">
        {data.map((node) => (
          <Post key={node.id} node={node} query={query} prefix={prefix} hideDate={hideDate} yearOnly={yearOnly} />
        ))}
      </div>
    )
  }
}