import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

function slugify(string) {
  return (
    string &&
    `${string}`
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-')
  )
}


export const PageSidebar = ({ Posttype }) => {

  const data = useStaticQuery(graphql`
    query pTaxonomiesQuery {    

      tech_years: allMdx (filter: { frontmatter: { categories: {eq: "technology"} } }){
        group(field: fields___year) {
          name: fieldValue
          totalCount
        }
      }
      tech_tags: allMdx (filter: { frontmatter: { categories: {eq: "technology"} } })
      {
        group(field: frontmatter___tags) {
          name: fieldValue
          totalCount
        }
      }

      spec_years: allMdx (filter: { frontmatter: { categories: {eq: "speculation"} } })
      {
        group(field: fields___year) {
          name: fieldValue
          totalCount
        }
      }
      spec_tags: allMdx (filter: { frontmatter: { categories: {eq: "speculation"} } })
      {
        group(field: frontmatter___tags) {
          name: fieldValue
          totalCount
        }
      }

      tht_years: allMdx (filter: { frontmatter: { categories: {eq: "thoughts"} } }){
        group(field: fields___year) {
          name: fieldValue
          totalCount
        }
      }
      tht_tags: allMdx (filter: { frontmatter: { categories: {eq: "thoughts"} } })
      {
        group(field: frontmatter___tags) {
          name: fieldValue
          totalCount
        }
      }

    }
  `)


  const tech_years = data.tech_years.group
  const tech_tags = data.tech_tags.group
  const tech_string = "tech"

  const spec_years = data.spec_years.group
  const spec_tags = data.spec_tags.group
  const spec_string = "spec"

  const tht_years = data.tht_years.group
  const tht_tags = data.tht_tags.group
  const tht_string = "thought"

  let years = tech_years
  let tags = tech_tags
  let strings = tech_string
  let total_posts = 0
  let i
  if ( Posttype === 'Technology'){
    years = tech_years
    tags = tech_tags
    strings = tech_string
    for(i=0; i<years.length; i++){
      total_posts = total_posts + years[i].totalCount
    }
  } else if ( Posttype === 'Speculation'){
    years = spec_years
    tags = spec_tags
    strings = spec_string
    for(i=0; i<years.length; i++){
      total_posts = total_posts + years[i].totalCount
    }
  } else {
    years = tht_years
    tags = tht_tags
    strings = tht_string
    for(i=0; i<years.length; i++){
      total_posts = total_posts + years[i].totalCount
    }
  }

  let post_string = 'posts'
  if (total_posts <= 1){
    post_string = 'post'
  }

  return (
    <aside className="post-sidebar">

      <div className="post-sidebar-card">
        <h2>Years [{total_posts} {post_string}]</h2>
        <div className="tags">
          {years.map((years) => {
            return (
              <Link
                key={years.name}
                to={`/${strings}-years/${slugify(years.name)}`}
                className="tag"
                activeClassName="active"
              >
                {years.name} ({years.totalCount})
              </Link>
            )
          })}
        </div>
      </div>


      <div className="post-sidebar-card">
        <h2>Tags</h2>
        <div className="tags">
          {tags.map((tag) => {
            return (
              <Link
                key={tag.name}
                to={`/${strings}-tags/${slugify(tag.name)}`}
                className="tag"
                activeClassName="active"
              >
                {tag.name} ({tag.totalCount})
              </Link>
            )
          })}
        </div>
      </div>


    </aside>
  )
}

