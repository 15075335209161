import React, { useMemo } from 'react'
import { graphql, useStaticQuery} from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/Base/Layout'
import { PageSidebar } from '../components/Base/PageSidebar'
import { PostsList } from '../components/Base/PagePostsList'
import { getSimplifiedPosts } from '../components/Utilities/helpers'


export default function WebsiteTechnology() {

  const data = useStaticQuery(graphql`
    query TechQuery {
      alltech: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { categories: {eq: "technology"} } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              tags
              categories
              published
            }
          }
        }
      }

    }
  `)

  const allthoughts = data.alltech.edges
  const simplifiedPosts= useMemo(() => getSimplifiedPosts(allthoughts), [allthoughts])
  const posttype = "Technology"


  return (
    <>
      <Layout>
        <div>
        <Helmet title="Technology - Ten Years" />

        <article className="hero">
          <header>
            <div className="container-thoughts">
                <div className="flex-content">
                  <h1>Technology</h1>
                </div>        
            </div>
          </header>

          <section className="container markdown-content">
            <div className="grid">
              <div className="sidebar-content">
                <PageSidebar Posttype={posttype} />
              </div>
              <div className="article-content">
                <PostsList data={simplifiedPosts} showYears />
              </div>
            </div>
          </section>

        </article>
      </div>
      </Layout>
    </>

  )
}


